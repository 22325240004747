.sectionThree{
    width: 100%;
    height: 649px;
    padding-top: 1px;
}

.sectionThree__grid{
    display: grid;
    grid-template-columns: 30% 70%;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 70%;
    margin-top: 6rem;
    gap: 2rem;
}

.sectionThree__textbox{
    color: #10273F;
}
.sectionThree__textbox h1{
    color: #10273F;
    font-family: 'Playfair Display', serif;
    font-size: 60px;
}

.sectionThree__textbox__grid{
    display: grid;
    grid-template-columns: 20% 80%;
    gap: 1rem;
    margin-top: 2rem;
}

.sectionThree__textbox__grid p #item{
    display: grid;
    grid-template-columns: 10% 90%;
    gap: 1rem;
}

.sectionThree__textbox__grid p #item img{
    width: 30px;
}

.sectionThree__grid .sectionThree__right{
    padding: 2rem;
}

.sectionThree__right form textarea{
    width: 100%;
    height: 140px;
    margin-top: 1rem;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #10273F;
    font-size: 18px;
}

.sectionThree__right--grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.sectionThree__right--grid input{
    height: 70px;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #10273F;
    font-size: 18px;
}

.sectionThree__right form input[type='submit']{
    width: 270px;
    height: 60px;
    border-radius: 5px;
    background: #90BE21;
    font-size: 18px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: 400ms;
    margin-top: 49px;
    color: #10273F;
}

.sectionThree__right form input[type='submit']:hover{
    border: 2px solid #90BE21;;
    border-radius: 5px;
    background: #F5F5F5;;
}

.sectionThree__right{
    position: relative;
}

#naturaPic2{
    position: absolute;
    left: 90%;
    top: 60%;
    width: 143px;
    height: 143px;
}

@media screen and (max-width: 1280px){
    #naturaPic2{
        width: 120px;
        height: 120px;
    }
}

@media screen and (max-width: 1216px){
    .sectionThree__textbox{
        width: 400px;
        margin-left: -5rem;
    }
}

@media screen and (max-width: 1100px){
    .sectionThree__textbox{
        width: 350px;
    }
}

@media screen and (max-width: 1030px){
    .sectionThree__right--grid input{
        width: 100%;
    }
    #naturaPic2{
        width: 100px;
        height: 100px;
        left: 85%;
    }
}

@media screen and (max-width: 940px){
    .sectionThree__textbox__grid{
        width: 320px;
    }
}

@media screen and (max-width: 900px){
    .sectionThree{
        margin-top: 13rem;
    }
    .sectionThree__grid{
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        gap: 0;
    }
    .sectionThree__left{
        padding: 40px;
    }
    .sectionThree__textbox__grid{
        grid-template-columns: 5% 95%;
        width: 100%;
    }
    .sectionThree__textbox__grid p #item{
        gap: 0.5rem;
        grid-template-columns: 5% 95%;
    }
    #naturaPic2{
        position: absolute;
        left: 88%;
        top: 60%;
        width: 89px;
        height: 89px;
    }
    .sectionThree__right form input[type='submit']{
        width: 222px;
        height: 50px;
    }
    .sectionThree__textbox{
        margin-top: 0rem;
        margin-left: 0;
        width: 100%;
    }
}

@media screen and (max-width: 760px){
    #naturaPic2{
        left: 84%;
    }
}

@media screen and (max-width: 610px){
    .sectionThree{
        margin-top: 50px;
        
    }
    .sectionThree__textbox{
        width: 280px;
        margin-top: 0rem;
        margin-left: 0;
    }
    .sectionThree__textbox__grid p #item{
        gap: 2rem;
    }
    .sectionThree__textbox__grid{
        grid-template-columns: 10% 90%;
    }
    .sectionThree__textbox__grid .hr hr{
        margin-top: 0.5rem;
    }
    .sectionThree__left{
        width: 100%;
    }
    .sectionThree__right--grid{
        grid-template-columns: 1fr;
    }
    #naturaPic2{
        position: absolute;
        left: 70%;
        top: 75%;
        width: 89px;
        height: 89px;
    }
    .sectionThree__textbox h1{
        font-size: 44px;
    }
}

