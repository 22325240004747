.sectionOne{
    width: 100%;
    height: 940px;
    display: grid;
    grid-template-columns: 55% 45%;
}

.sectionOne__right{
    width: 100%;
    position: relative;
}

.sectionOne__right #secOnePic{
    width: 100%;
    height: 940px;
}

.sectionOne__left{
    background: #10273F;
    width: 100%;
    height: 940px;;
}

.textbox{
    color: #FFFFFF;
    width: 469px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 180px;
}

.textbox h1{
    font-family: 'Playfair Display', serif;
    font-size: 60px;
    font-weight: 600;
}

.textbox__grid{
    display: grid;
    grid-template-columns: 10% 90%;
    gap: 1rem;
    margin-top: 2rem;
}

.textbox__grid .hr{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.textbox__grid .hr hr{
    margin-top: 0.5rem;
}

.textbox button{
    width: 270px;
    height: 60px;
    background: #90BE21;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    transition: 400ms;
    color: #10273F;
    border: none;
}

.textbox button:hover{
    border: 2px solid #90BE21;
    border-radius: 5px;
    background: #F5F5F5;;
}

#naturaPic{
    position: absolute;
    width: 176px;
    height: 176px;
    left: -80px;
    top: 371px;
}

@media screen and (max-width: 1285px){
    .sectionOne{
        height: 750px;
    }
    .sectionOne__left{
        height: 750px;
        position: relative;
    }
    .sectionOne__right #secOnePic{
        height: 750px;
    }
    .textbox{
        margin-top: 120px;
    }

    #naturaPic{
        top: 300px;
    }
}

@media screen and (max-width: 1153px){
    #naturaPic{
        width: 140px;
        height: 140px;
        left: -65px;
    }
}

@media screen and (max-width: 1100px){
    #naturaPic{
        width: 120px;
        height: 120px;
        left: -55px;
    }
}

@media screen and (max-width: 1030px){
    #naturaPic{
        width: 100px;
        height: 100px;
        left: -45px;
    }
}

@media screen and (max-width: 965px){
    #naturaPic{
        width: 85px;
        height: 85px;
        left: -40px;
    }
    .textbox{
        width: 400px;
    }
}

@media screen and (max-width: 900px){
    .sectionOne{
        width: 100%;
        height: 600px;
    }
    .sectionOne__right #secOnePic{
        width: 100%;
        height: 600px;
    }
    
    .sectionOne__left{
        position: relative;
        width: 100%;
        height: 600px;;
    }
    #naturaPic{
        position: absolute;
        width: 92px;
        height: 92px;
        left: -45px;
        top: 150px;
    }
    .textbox{
        width: 344px;
        margin-top: 70px;
    }
    .textbox button{
        width: 222px;
        height: 50px;
    }
    .textbox__grid p{
        font-size: 15px;
    }
    .textbox h1{
        font-size: 44px;
    }    
}

@media screen and (max-width: 684px){
    .textbox{
        width: 280px;
    }
    .textbox__grid{
        width: 280px;
    }
}

@media screen and (max-width: 665px){
    .textbox{
        width: 300px;
    }
}

@media screen and (max-width: 610px){
    .sectionOne{
        grid-template-columns: 1fr;
        height: 524px;
    }
    #naturaPic{
        position: absolute;
        width: 92px;
        height: 92px;
        left: 73%;
        top: -40px;
    }
    .sectionOne__right #secOnePic{
        width: 100%;
        height: 524px;
    }
    
    .sectionOne__left{
        position: relative;
        width: 100%;
        height: 524px;;
    }
}





