.footer{
    height: fit-content;
    width: 100%;
    background: #10273F;
    padding-left: 200px;
    padding-right: 200px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.footer__copyright p{
    color: #ffff;
    margin-top: 56px;
    font-size: 14px;
}

.footer__links #nav-ul{
    list-style: none;
}
.footer__links #nav-ul li #nav-item{
    text-decoration: none;
    color: #ffff;
    font-size: 17px;
}

.footer__links #nav-ul li{
    margin-bottom: 20px;
}

.footer__info span{
    display: grid;
    grid-template-columns: 5% 95%;
    gap: 2rem;
    margin-bottom: 23px;
    color:  #ffff;
    font-size: 18px;
}

.footer__socials{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 26px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}
.footer__none{
    display: none;
}

#copy__mobile{
    display: none;
}
@media screen and (max-width: 1281px){
    .footer{
        padding-right: 100px;
        padding-left: 100px;
    }
}

@media screen and (max-width: 1030px){
    .footer{
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 900px){
    .footer__none{
        display: flex;
    }
    #copy__mobile{
        display: flex;
        color: #ffff;
        font-size: 14px;
    }
    .footer{
        margin-top: 30rem;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        grid-template-areas: "footer__copyright footer__none footer__none"
                             "footer__links footer__info footer__socials";
        padding-left: 26px;
        padding-right: 34px;
        gap: 4rem;
    }
    .footer__copyright p{
        display: none;
    }
}

@media screen and (max-width: 760px){
    .footer{
        margin-top: 28rem;
        grid-template-columns: 1fr;
        grid-template-areas: none;
    }
    .footer__none{
        display: none;
    }
    .footer__copyright{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .footer__links{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .footer__links #nav-ul li{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer__info span{
        align-items: center;
        justify-content: center;
        display: flex;
    }
    #copy__mobile{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 615px){
    .footer{
        margin-top: 43rem;
    }
}