.header-mobile{
    display: none;
}

.header{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 50px;
    padding-left: 70px;
}

.header #nav-ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
}

.header #nav-ul li{
    margin-left: 3rem;
} 

.header #nav-ul li #nav-item{
    text-decoration: none;
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    color: #90BE21;
    font-weight: 500;
}

#open-btn{
    display: none;
}

.header .header__head img{
    width: 175px;
    height: 28px;
}

@media screen and (max-width: 1285px){
    .header{
        padding-left: 20px;
    }
}

@media screen and (max-width: 1185px){
    .header #nav-ul{
        display: none;
    }

    .header{
        padding-left: 50px;
        width: 179%;
        z-index: 2;
    }
    #open-btn{
        position: absolute;
        display: flex;
        font-size: 30px;
        top: -0.2rem;
        right: 1rem;
        z-index: 3;
        color: #90BE21;
    }
    .header__head{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        width: 170%;
    }
    .header__head img{
        position: absolute;
        left: 1rem;
        margin-top: 1rem;
    }
    .header-mobile{
        position: absolute;
        width: 181.8%;
        height: 80%;
        top: 0;
        padding-top: 45px;
        padding-left: 20px;
        background: #10273F;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        font-size: 18px;
        z-index: 2;
        animation: animationOpen .5s linear;
        transition: .5s ease-in-out;
    }

    @keyframes animationOpen{
        0%{
            transform: translateY(-500px);
        }
        100%{
            transform: translateY(0px);
        }
    }

    .header-mobile__head{
        display: flex;
        justify-content: flex-start;
        align-items: left;
    }

    #close-btn{
        font-size: 30px;
        color: #90BE21;
        position: absolute;
        right: 1rem;
    }

    #nav-ul-mobile{
        list-style: none;
    }

    #nav-ul-mobile li #nav-item{
        text-decoration: none;
        color: #90BE21;
        font-weight: 500;
    }

    #nav-ul-mobile li{
        margin-top: 2rem;
    }

    .header-mobile__socials{
        margin-top: 70px;
    }
    .header-mobile__socials a{
        margin-right: 26px;
    }
}

@media screen and (max-width: 900px){
    .header #nav-ul{
        display: none;
    }
    .header{
        padding-left: 50px;
        width: 179%;
        z-index: 2;
    }
    #open-btn{
        position: absolute;
        display: flex;
        font-size: 30px;
        top: -0.2rem;
        right: 1rem;
        z-index: 3;
        color: #90BE21;
    }
    .header__head{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        width: 170%;
    }
    .header__head img{
        position: absolute;
        left: 1rem;
        margin-top: 1rem;
    }
    .header-mobile{
        position: absolute;
        width: 181.8%;
        height: 80%;
        top: 0;
        padding-top: 45px;
        padding-left: 20px;
        background: #10273F;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        font-size: 18px;
        z-index: 2;
        animation: animationOpen .5s linear;
        transition: .5s ease-in-out;
    }

    @keyframes animationOpen{
        0%{
            transform: translateY(-500px);
        }
        100%{
            transform: translateY(0px);
        }
    }

    .header-mobile__head{
        display: flex;
        justify-content: flex-start;
        align-items: left;
    }

    #close-btn{
        font-size: 30px;
        color: #90BE21;
        position: absolute;
        right: 1rem;
    }

    #nav-ul-mobile{
        list-style: none;
    }

    #nav-ul-mobile li #nav-item{
        text-decoration: none;
        color: #90BE21;
        font-weight: 500;
    }

    #nav-ul-mobile li{
        margin-top: 2rem;
    }

    .header-mobile__socials{
        margin-top: 70px;
    }
    .header-mobile__socials a{
        margin-right: 26px;
    }
}

@media screen and (max-width: 760px){
    .header__head{
        width: 165%;
    }
}

@media screen and (max-width: 610px){
    .header{
        width: 100%;
    }
    .header__head{
        width: 86%;
    }

    #open-btn{
        right: 1rem;
    }
    .header__head img{
        left: -2rem;
    }
    .header-mobile{
        width: 100%;
    }
}

/*@media screen and (max-width: 1302px){
    .header{
        padding-left: 20px;
    }
}

@media screen and (max-width: 1220px){
    .header{
        padding-left: 10px;
    }
}*/