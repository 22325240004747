.sectionOneONS{
    width: 100%;
    height: 940px;
    display: grid;
    grid-template-columns: 50% 50%;
    position: relative;
}

.sectionOneONS__left{
    background: #F5F5F5;
}
.sectionOneONS__right{
    background-image: url('../../../public/images/nutraservices.png');
    background-repeat: no-repeat;
    background-size: 100% 940px;
}

.sectionOneONS__left .sectionOneONS__textbox{
    width: 507px;
    height: fit-content;
}

.sectionOneONS__left .sectionOneONS__textbox h1{
    font-size: 58px;
    font-family: 'Playfair Display', serif;
    color: #10273F;
}

.sectionOneONS__textbox__grid{
    display: grid;
    grid-template-columns: 10% 90%;
    gap: 1rem;
    margin-top: 2rem;
}

.sectionOneONS__textbox__grid .hr hr{
    margin-top: 0.5rem;
}

.sectionOneONS__textbox__grid p{
    color: #10273F;
    font-size: 18px;
}

.sectionOneONS__textbox__grid p button{
    width: 270px;
    height: 60px;
    border-radius: 5px;
    background: #90BE21;
    font-size: 18px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: 400ms;
    color: #10273F;
}

.sectionOneONS__textbox__grid p button:hover{
    border: 2px solid #90BE21;;
    border-radius: 5px;
    background: #F5F5F5;
}

.sectionOneONS__textbox{
    margin-left: 15%;
    margin-right: auto;
    margin-top: 10rem;
}

.sectionOneONS .footer{
    position: absolute;
    bottom: -35%;
    z-index: 3;
}

@media screen and (max-width: 1550px){
    .sectionOneONS__right{
        background-size: 100% 800px;
    }
    .sectionOneONS{
        background: #F5F5F5;
    }
}

@media screen and (max-width: 1415px){
    .sectionOneONS{
        grid-template-columns: 55% 45%;
    }
    .sectionOneONS__right{
        background-size: 100% 700px;
    }
    .sectionOneONS__left .sectionOneONS__textbox{
        margin-top: 5rem;
    }
}

@media screen and (max-width: 1300px){
    
    .sectionOneONS__right{
        background-size: 100% 650px;
    }
    .sectionOneONS__left .sectionOneONS__textbox h1{
        font-size: 44px;
    }
    .sectionOneONS__textbox__grid p{
        font-size: 15px;
    }
}

@media screen and (max-width: 1185px){
    .sectionOneONS__left{
        position: relative;
    }
}

@media screen and (max-width: 1112px){
    .sectionOneONS__left .sectionOneONS__textbox{
        width: 450px;
    }
    .sectionOneONS__right{
        background-size: 100% 550px;
    }
    .sectionOneONS__textbox__grid p button{
        width: 222px;
        height: 50px;
    }
}

@media screen and (max-width: 1050px){
    .sectionOneONS__left .sectionOneONS__textbox{
        width: 400px;
        margin-left: 10%;
    }
}

@media screen and (max-width: 1000px){
    .sectionOneONS{
        grid-template-columns: 1fr;
    }
    .sectionOneONS__right{
        height: 600px;
        background-image: url('../../../public/images/tablet3.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
    .sectionOneONS__left{
        position: relative;
        width: 55%;
        height: 600px;
    }
    body{
        background: #F5F5F5;
    }
    .sectionOneONS__left .sectionOneONS__textbox h1{
        font-size: 44px;
    }
    .sectionOneONS__textbox__grid p{
        font-size: 15px;
    }
    .sectionOneONS__left .sectionOneONS__textbox{
        margin-top: 5rem;
        margin-left: 15%;
    }
    .sectionOneONS .footer{
        bottom: -110%;
    }
}

@media screen and (max-width: 760px){
    .sectionOneONS .footer{
        bottom: -140%;
    }
}

@media screen and (max-width: 600px){
    @media screen and (max-width: 600px){
        .sectionOneONS__left .sectionOneONS__textbox{
            width: 330px;
            margin-top: 70px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        .sectionOneONS__right{
            height: 600px;
            background-image: url('../../../public/images/mobile2.png');
            background-repeat: no-repeat;
            background-size: 100% 400px;
        }
        .sectionOneONS__textbox__grid p button{
            width: 222px;
            height: 50px;
        }
        .sectionOneONS__left{
            width: 100%;
            height: 600px;
        }
        .sectionOneONS__left .sectionOneONS__textbox h1{
            font-size: 36px;
        }
        .sectionOneONS .footer{
            position: absolute;
            bottom: -120%;
        }
    }
}

@media screen and (max-width: 475px){
    .sectionOneONS .footer{
        bottom: -130%;
    }
}

@media screen and (max-width: 360px){
    .sectionOneONS .footer{
        bottom: -140%;
    }
}