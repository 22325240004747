.sectionTwo{
    height: 835px;
    width: 100%;
    background-image: url('../../../public/images/homeSec2.png');
    background-repeat: no-repeat;
    background-size: 690px 835px;
    padding-top: 1px;
}

.sectionTwo__textbox{
    width: 640px;
    height: fit-content;
    padding: 40px;
    background: #10273F;
    display: block;
    margin-right: auto;
    margin-left: 20%;
    box-sizing: border-box;
    margin-top: 15%;
}

.sectionTwo__textbox #green{
    color: #90BE21;
    font-size: 20px;
}

.sectionTwo__textbox p{
    color: #ffffff;
    font-size: 18px;
}

.sectionTwo__textbox #item{
  display: grid;
  grid-template-columns: 5% 95%;
  gap: 1rem;
}

.sectionTwo__textbox2{
    width: 400px;
    height: fit-content;
    color: #10273F;
    margin-top: -25rem;
    margin-left: 70%;
}

.sectionTwo__textbox2 h1{
    color: #10273F;
    font-family: 'Playfair Display', serif;
    font-size: 60px;
}

.sectionTwo__textbox2 .sectionTwo__textbox2__grid{
    display: grid;
    grid-template-columns: 10% 90%;
    margin-top: 2rem;
    gap: 1rem;
}

.sectionTwo__textbox2 .sectionTwo__textbox2__grid p button{
    width: 270px;
    height: 60px;
    border-radius: 5px;
    background: #90BE21;
    font-size: 18px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: 400ms;
    color: #10273F;
}

.sectionTwo__textbox2 .sectionTwo__textbox2__grid p button:hover{
    border: 2px solid #90BE21;;
    border-radius: 5px;
    background: #F5F5F5;
}

.sectionTwo__textbox2 .sectionTwo__textbox2__grid .hr hr{
    margin-top: 0.5rem;
}


@media screen and (max-width: 1370px){
    .sectionTwo__textbox2__grid{
        width: 350px;
    }
}

@media screen and (max-width: 1320px){
    .sectionTwo__textbox2{
        margin-left: 65%;
    }
    .sectionTwo__textbox{
       margin-left: 15%; 
    }
}

@media screen and (max-width: 1285px){
    .sectionTwo__textbox{
        margin-left: 12%;
    }
    .sectionTwo{
        background-size: 600px 700px;
        height: 700px;
    }
}

@media screen and (max-width: 1216px){
    .sectionTwo__textbox{
        margin-left: 9%;
    }
}

@media screen and (max-width: 1153px){
    .sectionTwo__textbox{
        margin-left: 6%;
    }
}

@media screen and (max-width: 1100px){
    .sectionTwo__textbox{
        width: 550px;
    }
    .sectionTwo{
        background-size: 450px 700px;
    }
    .sectionTwo__textbox2{
        margin-left: 60%;
    }
}

@media screen and (max-width: 1030px){
    .sectionTwo__textbox{
        width: 500px;
    }
    .sectionTwo__textbox2{
        margin-top: -30rem;
    }
}

@media screen and (max-width: 993px){
    .sectionTwo__textbox{
        width: 450px;
    }
    .sectionTwo__textbox2{
        margin-left: 55%;
    }
}

@media screen and (max-width: 940px){
    .sectionTwo__textbox{
        width: 430px;
    }
    .sectionTwo__textbox #green{
        font-size: 17px;
    }
    .sectionTwo__textbox p{
        font-size: 14px;
    }
    .sectionTwo__textbox2{
        margin-top: -27rem;
    }
    .sectionTwo__textbox2 h1{
        font-size: 50px;
    }
}

@media screen and (max-width: 900px){
    .sectionTwo{
        height: 498px;
        width: 100%;
        background-image: url('../../../public/images/tablet.png');
        background-repeat: no-repeat;
        background-size: 196px 498px;
        padding-top: 1px;
    }

    .sectionTwo__textbox{
        width: 100%;
        height: fit-content;
        padding: 40px;
        background: #10273F;
        display: block;
        box-sizing: border-box;
        margin-top: 31rem;
        margin-left: 0;
    }
    .sectionTwo__textbox2{
        width: 500px;
        height: fit-content;
        color: #10273F;
        margin-left: 30%;
        margin-top: -45rem;
    }
    .sectionTwo__textbox2 h1{
        font-size: 44px;
    }
    .sectionTwo__textbox2__grid p{
        font-size: 15px;
    }
    .sectionTwo__textbox2 .sectionTwo__textbox2__grid p button{
        width: 222px;
        height: 50px;
    }
    .sectionTwo__textbox #green{
        font-size: 18px;
    }
    .sectionTwo__textbox #item p{
        font-size: 15px;
    }
    .sectionTwo__textbox p{
        font-size: 15px;
    }
    .sectionTwo__textbox2__grid{
        width: 100%;
    }
}

@media screen and (max-width: 760px){
    .sectionTwo__textbox2{
        margin-top: -48rem;
        margin-left: 35%;
        width: 400px;
    }
}

@media screen and (max-width: 750px){
    .sectionTwo__textbox2__grid{
        width: 80%;
    }
}

@media screen and (max-width: 610px){
    .sectionTwo{
        margin-top: 50rem;
        position: relative;
        background-image: none;
    }
    .sectionTwo__textbox2{
        margin-left: auto;
        margin-right: auto;
        margin-top: -17rem;
        width: 320px;
    }
    .sectionTwo__textbox{
        width: 100%;
        height: fit-content;
        padding: 40px;
        background: #10273F;
        display: block;
        box-sizing: border-box;
        margin-top: 15rem;
        margin-left: 0;
        position: absolute;
    }
}