.sectionTwoONS{
    width: 100%;
    height: 200px;
    background: #F5F5F5;
    position: relative;
}

.sectionTwoONS__fbox{
    display: grid;
    grid-template-columns: 10% 90%;
    gap: 1rem;
    padding: 40px;
    width: 862px;
    height: fit-content;
    background: #10273F;  
    margin-right: auto;
    margin-left: auto;
    color: #ffff;
    font-size: 18px;
    margin-top: -10rem;
    position: absolute;
    right: 40%;
    border-radius: 5px;
}

.sectionTwoONS__fbox .hr hr{
    margin-top: 0.5rem;
}

@media screen and (max-width: 1750px){
    .sectionTwoONS__fbox{
        right: 30%;
    }
}

@media screen and (max-width: 1500px){
    .sectionTwoONS__fbox{
        right: 20%;
    }
}

@media screen and (max-width: 1415px){
    .sectionTwoONS__fbox{
        right: 10%;
        margin-top: -15rem;
    }
}

@media screen and (max-width: 1112px){
    .sectionTwoONS__fbox{
        right: 8%;
    }
}

@media screen and (max-width: 1080px){
    .sectionTwoONS__fbox{
        right: 7%;
    }
}

@media screen and (max-width: 1000px){
    .sectionTwoONS{
        z-index: -5;
    }
    .sectionTwoONS__fbox{
        top: 320%;
        width: 90%;
        right: 5%;
    }
}

@media screen and (max-width: 900px){
    .sectionTwoONS__fbox{
        top: 280%;
    }
}
@media screen and (max-width: 850px){
    .sectionTwoONS__fbox{
        top: 270%;
    }
}

@media screen and (max-width: 700px){
    .sectionTwoONS__fbox{
        top: 260%;
    }
}
@media screen and (max-width: 640px){
    .sectionTwoONS__fbox{
        top: 245%;
    }
}

@media screen and (max-width: 600px){
    .sectionTwoONS__fbox{
        top: 170%;
        right: 5%;
        width: 90%;
    }
}
