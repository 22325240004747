.sectionOneQS{
    height: 940px;
    width: 100%;
    display: grid;
    grid-template-columns: 55% 45%;
    position: relative;
}

.sectionOneQS__left{
    background: #F5F5F5;
    height: 940px;
}

.sectionOneQS__right{
    background-image: url('/public/images/qs.png');
    background-repeat: no-repeat;
    background-size: 100% 940px;
    height: 940px;
}

.sectionOneQS__left .sectionOneQS__textbox{
    width: 507px;
    height: fit-content;
}

.sectionOneQS__left .sectionOneQS__textbox h1{
    font-size: 60px;
    font-family: 'Playfair Display', serif;
    color: #10273F;
}

.sectionOneQS__textbox__grid{
    display: grid;
    grid-template-columns: 10% 90%;
    gap: 1rem;
    margin-top: 2rem;
}

.sectionOneQS__textbox__grid .hr hr{
    margin-top: 0.5rem;
}

.sectionOneQS__textbox__grid p{
    color: #10273F;
    font-size: 18px;
}

.sectionOneQS__textbox__grid p button{
    width: 270px;
    height: 60px;
    border-radius: 5px;
    background: #90BE21;
    font-size: 18px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: 400ms;
    color: #10273F;
}

.sectionOneQS__textbox__grid p button:hover{
    border: 2px solid #90BE21;;
    border-radius: 5px;
    background: #F5F5F5;
}

.sectionOneQS__textbox{
    margin-left: 15%;
    margin-right: auto;
    margin-top: 10rem;
}

.sectionOneQS__textbox2{
    width: 640px;
    height: 503px;
    background: #10273F;
    color: #ffff;
    padding: 40px;
    margin-top: 30%;
    margin-left: -15%;
}

.sectionOneQS__textbox2 #green{
    color: #90BE21;
    font-size: 22px;
}

.sectionOneQS__textbox2 #item{
    display: grid;
    grid-template-columns: 5% 95%;
    gap: 1rem;
}

.sectionOneQS__textbox2 #item p{
    font-size: 20px;
}

.sectionOneQS__textbox2 p{
    font-size: 22px;
}

.sectionOneQS .footer{
    position: absolute;
    bottom: -23%;
    width: 100%;
}

@media screen and (max-width:1333px){
    .sectionOneQS__textbox2{
        width: 580px;
        margin-left: -12%;
        height: 450px;
    }
    .sectionOneQS__textbox2 #green{
        font-size: 20px;
    }
    .sectionOneQS__textbox2 #item p{
        font-size: 18px;
    }
    .sectionOneQS__textbox2 p{
        font-size: 20px;
    }
    .sectionOneQS__textbox2{
        margin-top: 37%;
    }
}

@media screen and (max-width: 1271px){
    .sectionOneQS__left .sectionOneQS__textbox{
        width: 490px;
        margin-left: 10%;
    }
    .sectionOneQS__textbox__grid{
        width: 420px;
    }
    .sectionOneQS__textbox2{
        margin-top: 45%;
    }
}

@media screen and (max-width: 1200px){
    .sectionOneQS__textbox2{
        position: absolute;
        height: fit-content;
    }
    .sectionOneQS__left{
        position: relative;
    }
    .sectionOneQS .footer{
        bottom: -35%;
    }
}

@media screen and (max-width: 1200px){
    .sectionOneQS__left .sectionOneQS__textbox{
        width: 400px;
        margin-left: 7%;
    }
    .sectionOneQS__textbox__grid p{
        font-size: 15px;
    }
    .sectionOneQS__left .sectionOneQS__textbox h1{
        font-size: 45px;
    }
    .sectionOneQS__left .sectionOneQS__textbox{
        margin-top: 10rem;
    }
    .sectionOneQS__textbox__grid{
        width: 370px;
    }
    .sectionOneQS__textbox2 #green{
        font-size: 15px;
    }
    .sectionOneQS__textbox2 #item p{
        font-size: 13px;
    }
    .sectionOneQS__textbox2 p{
        font-size: 15px;
    }
    .sectionOneQS__textbox2{
        top: -38%;
        margin-left: -15%;
    }
    .sectionOneQS{
        height: 800px;
    }
    .sectionOneQS__left{
        height: 800px;
    }
    .sectionOneQS__right{
        height: 800px;
    }
}

@media screen and (max-width: 1130px){
    .sectionOneQS__textbox2{
        top: -35%;
    }
}

@media screen and (max-width: 1095px){
    .sectionOneQS__textbox2{
        margin-left: -10%;
        top: -36%;
        width: 450px;
    }
}

@media screen and (max-width: 1029px){
    .sectionOneQS__textbox2{
        top: -31%;
        margin-left: -7%;
    }
}

@media screen and (max-width: 965px){
    .sectionOneQS__textbox2{
        top: -28%;
    }
}

@media screen and (max-width: 931px){
    .sectionOneQS__textbox2{
        top: -25%;
        margin-left: -5%;
    }
}

@media screen and (max-width: 900px){
    .sectionOneQS{
        height: 678px;
        width: 100%;
    }

    .sectionOneQS__left{
        background: #F5F5F5;
        height: 678px;
        position: relative;
        width: 100%;
    }
    
    .sectionOneQS__right{
        background-image: url('/public/images/tablet2.png');
        background-repeat: no-repeat;
        background-size: 100% 678px;
        width: 100%;
        height: 678px;
        z-index: -1;
        position: relative;
    }

    .sectionOneQS__left .sectionOneQS__textbox{
        margin-left: 5%;
        margin-right: 0;
        margin-top: 110px;
        width: 100%;
    }

    .sectionOneQS__left .sectionOneQS__textbox h1{
        font-size: 44px;
    }

    .sectionOneQS__textbox__grid{
        width: 344px;
    }
    .sectionOneQS__textbox__grid p{
        font-size: 15px;
    }
    .sectionOneQS__textbox2 p{
        font-size: 15px;
    }
    .sectionOneQS__textbox2{
        position: absolute;
        top: 80%;
        left: -113.5%;
        height: 350px;
        width: 215%;
    }
    .sectionOneQS__textbox2 #green{
        font-size: 18px;
    }
    .sectionOneQS__textbox2 #item p{
        font-size: 15px;
    }
    .sectionOneQS .footer{
        top: 100%;
    }
    .sectionOneQS__textbox__grid p button{
        width: 220px;
        height: 50px;
    }
}

@media screen and (max-width: 800px){
    .sectionOneQS{
        height: 550px;
    }
    .sectionOneQS__left{
        height: 550px;
    }
    .sectionOneQS__right{
        background-image: url('/public/images/tablet2.png');
        background-repeat: no-repeat;
        background-size: 100% 550px;
        width: 100%;
        height: 550px;
        z-index: -1;
        position: relative;
    }
    .sectionOneQS__left .sectionOneQS__textbox{
        margin-top: 80px;
    }
}

@media screen and (max-width: 703px){
    .sectionOneQS__left .sectionOneQS__textbox h1{
        font-size: 40px;
    }
    .sectionOneQS__left .sectionOneQS__textbox{
        width: 100%;
    }
    .sectionOneQS__textbox__grid{
        width: 300px;
    }
}

@media screen and (max-width: 641px){
    .sectionOneQS__left .sectionOneQS__textbox h1{
        font-size: 35px;
    }
    .sectionOneQS__left .sectionOneQS__textbox{
        width: 100%;
    }
    .sectionOneQS__textbox__grid{
        width: 280px;
    }
}

@media screen and (max-width: 615px){
    .sectionOneQS{
        grid-template-columns: 1fr;
        height: 400px;
        width: 100%;
    }
    .sectionOneQS__left{
        background: #F5F5F5;
        height: 600px;
        position: relative;
        width: 100%;
    }
    
    .sectionOneQS__right{
        background-image: url('/public/images/mobile.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        width: 100%;
        z-index: -1;
        position: relative;
    }
    .sectionOneQS__left .sectionOneQS__textbox{
        width: 300px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 70px;
    }
    .sectionOneQS__textbox__grid{
        width: 300px;
    }
    .sectionOneQS__textbox__grid p button{
        width: 222px;
        height: 60px;
    }
    .sectionOneQS__textbox2{
        top: 60%;
        left: 11%;
        width: 90%;
        height: fit-content;
    }
    .sectionOneQS .footer{
        top: 230%;
    }
    .sectionOneQS__textbox2 #item{
        gap: 2rem;
    }
}

@media screen and (max-width: 534px){
    .sectionOneQS__textbox2{
        top: 58%;
    }
}

@media screen and (max-width: 500px){
    .sectionOneQS__textbox2{
        top: 61%;
    }
}

@media screen and (max-width: 492px){
    .sectionOneQS__textbox2{
        top: 61%;
    }
}

@media screen and (max-width: 442px){
    .sectionOneQS__textbox2{
        top: 55%;
    }
}

@media screen and (max-width: 390px){
    .sectionOneQS__textbox2{
        top: 50%;
    }
}